import React from 'react'
import Seo from '../components/seo'

const modQuiz = () => {
  return (
    <div>
      <Seo />
      <form name="ame-module-quiz" method="post" action="/myame" data-netlify="true" data-netlify-honeypot="bot-field" class="css-5qse13">
  <p>
      <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
    </p>
     <input type="text" name="rating" required="" class="css-p19g2b" />
     <input type="text" name="uid" id="uid" class="css-dlo3m6" required="" />
     <input type="text" name="org" id="org" class="css-dlo3m6" required="" />
     <input type="text" name="course" id="course" class="css-dlo3m6" required="" />
     <input type="text" name="module" id="module" class="css-dlo3m6" required="" />
     <input type="hidden" name="form-name" class="css-dlo3m6" value="ame-module-quiz" />
     <input type="text" name="q1" required="" class="css-p19g2b" />
     <input type="text" name="q2" required="" class="css-p19g2b" />
     <input type="text" name="q3" required="" class="css-p19g2b" />
     <input type="text" name="q4" required="" class="css-p19g2b" />
     <input type="text" name="q5" required="" class="css-p19g2b" />
     <button type="submit" class="css-1oje8pf">Submit</button>
</form>
    </div>
  )
}

export default modQuiz
